<template>
  <ul>
    <template v-if="mspForsageOrders">
      <li v-for="(order, index) in mspForsageOrders" :key="index" :class="{'active': mspForsageRoutes[programRoute(order)].indexOf($route.name) > -1}">
        <router-link :to="`/lk/${programRoute(order)}/company`"><img src="/pic/lk-accelerator-menu.svg">{{ order.enroll_program.name }}</router-link>
        <div class="section__lk-submenu">
          <div><router-link :to="`/lk/${programRoute(order)}/company`">Данные о компании</router-link></div>
          <div><router-link :to="`/lk/${programRoute(order)}/members`">Регистрация сотрудников</router-link></div>
          <div><router-link :to="`/lk/${programRoute(order)}/profile`">Профиль компании</router-link></div>
          <div><router-link :to="`/lk/${programRoute(order)}/docs`">Документы компании</router-link></div>
        </div>
      </li>
    </template>
    <li :class="{'active': settingsRoutes.indexOf($route.name) > -1}">
      <router-link to="/lk/company"><img src="/pic/lk-settings.svg">Настройки</router-link>
      <div class="section__lk-submenu">
        <div><router-link to="/lk/company">Данные о компании</router-link></div>
        <div><router-link to="/lk/subscribes">Рассылки и подписки</router-link></div>
      </div>
    </li>
  </ul>
</template>

<script>
import {mapGetters} from "vuex";
import roleRoutersMixin from "@/mixins/roleRoutersMixin";

export default {
  name: "ProfileMenuMspForsage",
  mixins: [roleRoutersMixin],
  methods: {
    programRoute(program) {
      return program.enroll_program.id === 1 ? 'forsage' : 'msp';
    }
  },
  computed: {
    ...mapGetters(['mspForsageOrders'])
  }
}
</script>

<style scoped>

</style>