<template>
  <div class="section__lk-menu-mobile d-sm-none">
    <div class="toggle"></div>
    <template v-for="(order, index) in mspForsageOrders">
      <template v-if="mspForsageRoutes[programRoute(order)].indexOf($route.name) > -1">
        <span :key="'head-' + index"><img src="/pic/lk-accelerator-menu.svg">{{ order.enroll_program.name }}</span>
        <div :key="'section-' + index" class="section__lk-submenu">
          <div><router-link :to="`/lk/${programRoute(order)}/company`">Данные о компании</router-link></div>
          <div><router-link :to="`/lk/${programRoute(order)}/members`">Регистрация сотрудников</router-link></div>
          <div><router-link :to="`/lk/${programRoute(order)}/profile`">Профиль компании</router-link></div>
          <div><router-link :to="`/lk/${programRoute(order)}/docs`">Документы компании</router-link></div>
        </div>
      </template>
    </template>
    <template v-if="settingsRoutes.indexOf($route.name) > -1">
      <span><img src="/pic/lk-settings.svg">Настройки</span>
      <div class="section__lk-submenu">
        <div><router-link to="/lk/company">Данные о компании</router-link></div>
        <div><router-link to="/lk/subscribes">Рассылки и подписки</router-link></div>
      </div>
    </template>
  </div>
</template>

<script>
import roleRoutersMixin from "@/mixins/roleRoutersMixin";
import mspProgramMixin from "@/mixins/mspProgramMixin";
import {mapGetters} from "vuex";

export default {
  name: "ProfileMenuMspForsageMobile",
  mixins: [roleRoutersMixin, mspProgramMixin],
  computed: {
    ...mapGetters(['mspForsageOrders'])
  },
  methods: {
    programRoute(program) {
      return program.enroll_program.id === 1 ? 'forsage' : 'msp';
    }
  },
}
</script>

<style scoped>

</style>