// @ts-nocheck
export default {
    computed: {
        acceleratorRoutes() {
            return ['AcceleratorCompany', 'AcceleratorMember', 'AcceleratorProfile', 'AcceleratorDocs']
        },
        mspForsageRoutes() {
            return {
                msp: ['MspCompany', 'MspMember', 'MspProfile', 'MspDocs'],
                forsage: ['ForsageCompany', 'ForsageMember', 'ForsageProfile', 'ForsageDocs']
            }
        },
        settingsRoutes() {
            return ['ProfileCompanySettings', 'SubscribeSettings', 'ProfileSettings']
        },
        regionTeamsRoutes() {
            return ['RegionTeam', 'RegionTeamForm', 'RegionTeamMembers']
        }
    }
}