import { render, staticRenderFns } from "./ProfileMenuMspForsageMobile.vue?vue&type=template&id=e888593a&scoped=true&"
import script from "./ProfileMenuMspForsageMobile.vue?vue&type=script&lang=js&"
export * from "./ProfileMenuMspForsageMobile.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e888593a",
  null
  
)

export default component.exports