<template>
  <div class="section__lk-menu-mobile d-sm-none">
    <div class="toggle"></div>
    <template v-if="mspForsageOperator && ['MspForsageOperatorOrders', 'MspForsageGroups'].indexOf($route.name) > -1">
      <div v-for="(menu, index) in mspForsageOperator.programs" :key="'mb-' + index + menu.id"  :class="{'active': ['MspForsageOperatorOrders', 'MspForsageGroups'].indexOf($route.name) > -1}">
        <template v-if="menu.id == currentProgram">
          <span :key="'head-' + index + menu.id"><img src="/pic/lk-accelerator-menu.svg">{{ menu.name }}</span>
          <div :key="'section-' + index + menu.id" class="section__lk-submenu">
            <div><router-link :to="`/lk/msp-forsage/orders?program=${menu.id}`">Новые заявки</router-link></div>
            <div><router-link :to="`/lk/msp-forsage/groups?program=${menu.id}`">Потоки обучения</router-link></div>
          </div>
        </template>
      </div>
    </template>
  </div>
</template>

<script>

import mspForsageOperatorMixin from "@/mixins/mspForsageOperatorMixin";
import roleRoutersMixin from "@/mixins/roleRoutersMixin";

export default {
  name: "ProfileMenuMspForsageOperatorMobile",
  mixins: [mspForsageOperatorMixin, roleRoutersMixin]
}
</script>

<style scoped>

</style>