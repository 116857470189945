<template>
  <div class="section section__lk">
    <div class="section__wrapper section__subsection-wrapper">
      <profile-header/>
      <div class="section__lk-wrapper">
        <profile-menu />
        <router-view/>
      </div>
    </div>
  </div>
</template>

<script>
import ProfileMenu from "@/components/profile/menu/ProfileMenu.vue";
import ProfileHeader from "@/components/profile/ProfileHeader.vue";
import {TokenService} from "../../../services/token.service";

export default {
  name: "Profile",
  components: {ProfileHeader, ProfileMenu},
  mounted() {
    this.$parent.init()
    if(this.$hasRoles(['ROLE_MSP', 'ROLE_FORSAGE']) && this.$route.path === '/lk') {

      if(this.$getUser()) {
        if(this.$getUser().msp_forsage_order && this.$getUser().msp_forsage_order.enroll_program && this.$getUser().msp_forsage_order.enroll_program.id === 1) {
          this.$router.push('/lk/forsage/company').catch(err => err)
        } else {
          this.$router.push('/lk/msp/company').catch(err => err)
        }
      }
      this.$router.push('/lk/company').catch(err => err)
    } else if(this.$hasRoles(['ROLE_OPERATOR_MSP_MBA']) && this.$route.path === '/lk') {
      this.$router.push('/lk/msp-forsage').catch(err => err)
    } else if(this.$isOLdLk()) {
      localStorage.removeItem('access:path')
      window.location.href = '/profile/settings/personal'
    }
  },
  data() {
    return {
      user: JSON.parse(TokenService.getUser())
    }
  }
}
</script>

<style scoped>

</style>