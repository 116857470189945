<template>
  <ul>
    <li :class="{'active': acceleratorRoutes.indexOf($route.name) > -1}">
      <router-link to="/lk/accelerator"><img src="/pic/lk-accelerator-menu.svg">Акселератор</router-link>
      <div class="section__lk-submenu">
        <div><router-link to="/lk/accelerator/company" :class="{'active': $route.name === 'AcceleratorCompany'}">Данные о компании</router-link></div>
        <div><router-link to="/lk/accelerator/members">Регистрация сотрудников</router-link></div>
        <div><router-link to="/lk/accelerator/profile">Профиль компании</router-link></div>
        <div><router-link to="/lk/accelerator/docs">Документы компании</router-link></div>
      </div>
    </li>
    <li :class="{'active': settingsRoutes.indexOf($route.name) > -1}">
      <router-link to="/lk/company"><img src="/pic/lk-settings.svg">Настройки</router-link>
      <div class="section__lk-submenu">
        <div><router-link to="/lk/company">Данные о компании</router-link></div>
        <div><router-link to="/lk/subscribes">Рассылки и подписки</router-link></div>
      </div>
    </li>
  </ul>
</template>

<script>
import roleRoutersMixin from "@/mixins/roleRoutersMixin";

export default {
  name: "ProfileMenuCompany",
  mixins: [roleRoutersMixin]
}
</script>

<style scoped>

</style>