<template>
  <div class="section__lk-menu-mobile d-sm-none">
    <div class="toggle"></div>
    <template v-if="regionTeamsRoutes.indexOf($route.name) > -1">
      <span><img src="/pic/lk-settings.svg">Обучение региональных управленческих команд</span>
      <div class="section__lk-submenu">
        <div><router-link :class="{active: $route.name === 'RegionTeamForm'}" to="/lk/region-team/form">Мои сведения</router-link></div>
        <div><router-link to="/lk/region-team/members">Сотрудники</router-link></div>
      </div>
    </template>
    <template v-if="settingsRoutes.indexOf($route.name) > -1">
      <span><img src="/pic/lk-settings.svg">Настройки</span>
      <div class="section__lk-submenu">
        <div><router-link to="/lk/company">Данные о компании</router-link></div>
        <div><router-link to="/lk/subscribes">Рассылки и подписки</router-link></div>
      </div>
    </template>
    <template v-if="$route.name === 'Ebs'">
      <span><img src="/pic/lk-elib.svg">Электронная библиотека</span>
    </template>
  </div>
</template>

<script>
import roleRoutersMixin from "@/mixins/roleRoutersMixin";

export default {
  name: "ProfileMenuRegionTeamMobile",
  mixins: [roleRoutersMixin]
}
</script>

<style scoped>

</style>