<template>
  <svg width="32" height="32" viewBox="0 0 144 144" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_2926_96876)">
      <path d="M72 71.8184C61.144 71.8184 52.3125 80.6488 52.3125 91.503C52.3125 102.357 61.144 111.188 72 111.188C82.856 111.188 91.6875 102.357 91.6875 91.503C91.6875 80.6488 82.856 71.8184 72 71.8184ZM72 102.752C65.7968 102.752 60.75 97.7055 60.75 91.5033C60.75 85.3009 65.7968 80.255 72 80.255C78.2033 80.255 83.25 85.3012 83.25 91.5033C83.25 97.7055 78.2033 102.752 72 102.752Z" :fill="fill"/>
      <path d="M144 0H0V95.7212H23.7592L0.0728438 110.489L18.6486 122.12L20.7458 144L52.6073 124.113L61.4897 123.85L72 131.99L82.5106 123.85L91.3927 124.113L123.254 144L125.351 122.12L143.927 110.488L120.241 95.7212H144V0ZM26.651 117.176L15.9947 110.504L35.5326 98.323L33.4839 104.019L44.489 111.485L46.3978 118.043L27.844 129.624L26.651 117.176ZM79.7361 115.328L72 121.319L64.2642 115.328L54.4795 115.618L51.7413 106.21L43.6393 100.714L46.9519 91.5033L43.6393 82.2926L51.7413 76.7962L54.4795 67.3889L64.2639 67.6786L72 61.6874L79.7358 67.6783L89.5205 67.3886L92.2587 76.7959L100.361 82.2923L97.0481 91.503L100.361 100.714L92.2587 106.21L89.5205 115.617L79.7361 115.328ZM117.349 117.176L116.156 129.624L97.6022 118.043L99.511 111.485L110.516 104.019L108.467 98.323L128.005 110.504L117.349 117.176ZM135.562 87.2848H107.531L110.516 78.9868L99.5107 71.5208L95.7974 58.7624L82.5103 59.1559L72 51.0162L61.4894 59.1562L48.2026 58.7627L44.4893 71.521L33.4842 78.9871L36.4686 87.2851H8.4375V8.43637H135.562V87.2848Z" :fill="fill"/>
      <path d="M25.5635 17.4351H118.438V25.8714H25.5635V17.4351Z" :fill="fill"/>
      <path d="M42.4385 34.3076H101.563V42.744H42.4385V34.3076Z" :fill="fill"/>
    </g>
    <defs>
      <clipPath id="clip0_2926_96876">
        <rect width="144" height="144" fill="white"/>
      </clipPath>
    </defs>
  </svg>

</template>

<script>
import iconMixin from "@/mixins/iconMixin";
export default {
  name: "IconAttestation",
  mixins: [iconMixin]
}
</script>

<style scoped>

</style>