<template>
  <div class="section__lk-menu">
    <nav>
      <profile-menu-msp-forsage-operator v-if="$hasRole('ROLE_OPERATOR_MSP_MBA')" />
      <profile-menu-user v-if="!$hasRoles(['ROLE_REGION_TEAM', 'ROLE_COMPANY', 'ROLE_MSP', 'ROLE_FORSAGE'])" />
      <profile-menu-company v-else-if="$hasRole('ROLE_COMPANY')" />
      <profile-menu-region-team v-else-if="$hasRole('ROLE_REGION_TEAM')" />
      <profile-menu-msp-forsage v-else-if="$hasRoles(['ROLE_MSP', 'ROLE_FORSAGE'])" />

      <profile-menu-msp-forsage-operator-mobile v-if="$hasRole('ROLE_OPERATOR_MSP_MBA')" />
      <profile-menu-user-mobile v-if="!$hasRoles(['ROLE_REGION_TEAM', 'ROLE_COMPANY', 'ROLE_MSP', 'ROLE_FORSAGE'])" />
      <profile-menu-company-mobile v-else-if="$hasRole('ROLE_COMPANY')" />
      <profile-menu-region-team-mobile v-else-if="$hasRole('ROLE_REGION_TEAM')" />
      <profile-menu-msp-forsage-mobile v-else-if="$hasRoles(['ROLE_MSP', 'ROLE_FORSAGE'])" />
    </nav>
  </div>
</template>

<script>
import $ from 'jquery'
import 'chosen-js'
import ProfileMenuUserMobile from "@/components/profile/menu/user/ProfileMenuUserMobile.vue";
import ProfileMenuCompanyMobile from "@/components/profile/menu/company/ProfileMenuCompanyMobile.vue";
import ProfileMenuUser from "@/components/profile/menu/user/ProfileMenuUser.vue";
import ProfileMenuCompany from "@/components/profile/menu/company/ProfileMenuCompany.vue";
import ProfileMenuRegionTeam from "@/components/profile/menu/regionteam/ProfileMenuRegionTeam.vue";
import ProfileMenuRegionTeamMobile from "@/components/profile/menu/regionteam/ProfileMenuRegionTeamMobile.vue";
import ProfileMenuMspForsage from "@/components/profile/menu/mspForsage/ProfileMenuMspForsage.vue";
import ProfileMenuMspForsageMobile from "@/components/profile/menu/mspForsage/ProfileMenuMspForsageMobile.vue";
import ProfileMenuMspForsageOperator
  from "@/components/profile/menu/mspForsageOperator/ProfileMenuMspForsageOperator.vue";
import ProfileMenuMspForsageOperatorMobile
  from "@/components/profile/menu/mspForsageOperator/ProfileMenuMspForsageOperatorMobile.vue";
export default {
  name: "ProfileMenu",
  components: {
    ProfileMenuMspForsageOperatorMobile,
    ProfileMenuMspForsageOperator,
    ProfileMenuMspForsageMobile,
    ProfileMenuMspForsage,
    ProfileMenuRegionTeamMobile,
    ProfileMenuRegionTeam,
    ProfileMenuCompany, ProfileMenuUser, ProfileMenuCompanyMobile, ProfileMenuUserMobile},
  mounted() {

    $('.fp-viewing-front').removeClass('fp-viewing-front')

    // personal cabinet
    $('.section__lk-menu-mobile .toggle').click(function(){
      $(this).parents('nav').toggleClass('open');
      $(this).parents('nav').find('ul');

      return false;
    });

    function eachOther() {
      // personal cabinet / submenu
      $(".section__lk-menu li").each(function () {
        if ($('.section__lk-submenu', this).length) {
          $(this).addClass('has-child');
          let indicator = $('<span class="indicator" />').appendTo($(this));

          indicator.click(function () {
            let link_wrapper = $(this).parent('li');
            link_wrapper.toggleClass('active');
          });
        }
      });
    }

    eachOther()

    $(".section__lk-menu li").click(function () {
      eachOther()
    });

    $("#non-resident").click(function(){
      if($(this).is(':checked')){
        $(this).parents('form').find('label[for="region"]').text('Регион пребывания');
        $(this).parents('form').find('label[for="address"]').text('Почтовый адрес пребывания');
      }
      else {
        $(this).parents('form').find('label[for="region"]').text('Регион');
        $(this).parents('form').find('label[for="address"]').text('Почтовый адрес');
      }
    });
  }
}
</script>

<style scoped>

</style>