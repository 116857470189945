<template>
  <div class="section__lk-menu-mobile d-sm-none">
    <template v-if="settingsRoutes.indexOf($route.name) > -1">
      <span><img src="/pic/lk-settings.svg">Настройки</span>
      <div class="section__lk-submenu">
        <div><router-link to="/lk/company">Данные о компании</router-link></div>
        <div><router-link to="/lk/subscribes">Рассылки и подписки</router-link></div>
      </div>
    </template>
    <div class="toggle"></div>
    <span><img src="/pic/lk-accelerator-menu.svg">Акселератор</span>
    <div class="section__lk-submenu">
      <div><router-link to="/lk/accelerator/company" :class="{'active': $route.name === 'AcceleratorCompany'}">Данные о компании</router-link></div>
      <div><router-link to="/lk/accelerator/members">Регистрация сотрудников</router-link></div>
      <div><router-link to="/lk/accelerator/profile">Профиль компании</router-link></div>
      <div><router-link to="/lk/accelerator/docs">Документы компании</router-link></div>
    </div>
  </div>
</template>

<script>
import roleRoutersMixin from "@/mixins/roleRoutersMixin";

export default {
  name: "ProfileMenuCompanyMobile",
  mixins: [roleRoutersMixin]
}
</script>

<style scoped>

</style>