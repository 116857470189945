<template>
  <div class="d-flex justify-content-between">
    <div class="title-with-icon mb-5">
      <div class="title-with-icon__icon title-with-icon__icon_lk"></div>
      <div class="title-with-icon__title">Личный кабинет</div>
    </div>
    <div class="d-md-none d-sm-block d-none my-3">
      <div class="row">
        <div class="col"></div>
        <div class="col-auto">
          <div class="table-wrapper_indicator"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProfileHeader"
}
</script>

<style scoped>

</style>