<template>
  <ul>
    <template v-if="mspForsageOperator">
      <li v-for="(menu, index) in mspForsageOperator.programs" :key="index"  :class="{'active': ['MspForsageOperatorOrders', 'MspForsageGroups'].indexOf($route.name) > -1 && menu.id == currentProgram}">
        <router-link :to="`/lk/msp-forsage/orders?program=${menu.id}`"><img src="/pic/lk-accelerator-menu.svg">{{ menu.name }}</router-link>
        <div class="section__lk-submenu">
          <div><router-link :to="`/lk/msp-forsage/orders?program=${menu.id}`">Новые заявки</router-link></div>
          <div><router-link :to="`/lk/msp-forsage/groups?program=${menu.id}`">Потоки обучения</router-link></div>
        </div>
      </li>
    </template>
  </ul>
</template>

<script>


import mspForsageOperatorMixin from "@/mixins/mspForsageOperatorMixin";
import roleRoutersMixin from "@/mixins/roleRoutersMixin";

export default {
  name: "ProfileMenuMspForsageOperator",
  mixins: [mspForsageOperatorMixin, roleRoutersMixin]
}
</script>

<style scoped>

</style>