import { render, staticRenderFns } from "./ProfileMenuUser.vue?vue&type=template&id=219ffa6a&scoped=true&"
import script from "./ProfileMenuUser.vue?vue&type=script&lang=js&"
export * from "./ProfileMenuUser.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "219ffa6a",
  null
  
)

export default component.exports