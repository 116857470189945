<template>
  <ul>
    <li :class="{'active': regionTeamsRoutes.indexOf($route.name) > -1}">
      <router-link class="mb-2" to="/lk/region-team"><img src="/pic/lk-settings.svg">Обучение региональных управленческих команд</router-link>
      <div class="section__lk-submenu">
        <div><router-link :class="{active: $route.name === 'RegionTeamForm'}" to="/lk/region-team/form">Мои сведения</router-link></div>
        <div><router-link to="/lk/region-team/members">Сотрудники</router-link></div>
      </div>
    </li>
    <router-link tag="li" to="/lk/ebs">
      <a href="#"><img src="/pic/lk-elib.svg">Электронная библиотека</a>
    </router-link>
    <li :class="{'active': settingsRoutes.indexOf($route.name) > -1}">
      <router-link to="/lk/company"><img src="/pic/lk-settings.svg">Настройки</router-link>
      <div class="section__lk-submenu">
        <div><router-link to="/lk/company">Данные о компании</router-link></div>
        <div><router-link to="/lk/subscribes">Рассылки и подписки</router-link></div>
      </div>
    </li>
  </ul>
</template>

<script>
import roleRoutersMixin from "@/mixins/roleRoutersMixin";

export default {
  name: "ProfileMenuRegionTeam",
  mixins: [roleRoutersMixin]
}
</script>

<style scoped>

</style>