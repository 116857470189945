import { render, staticRenderFns } from "./ProfileMenu.vue?vue&type=template&id=5d97cf40&scoped=true&"
import script from "./ProfileMenu.vue?vue&type=script&lang=js&"
export * from "./ProfileMenu.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5d97cf40",
  null
  
)

export default component.exports